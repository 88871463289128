
import { defineComponent, ref, PropType, watch } from "vue";
import { IonRow, IonCol, IonRadioGroup, IonItem, IonRadio, IonToggle, IonText, IonGrid } from "@ionic/vue";
import { arrowForwardOutline } from "ionicons/icons";
import { Account } from "@/models/account/authModels";
import CommunicationPreferences from "@/models/account/communicationPreferences";
import { updateCommunicationPreferences } from "@/services/accountService";

export default defineComponent({
  props: { account: Object as PropType<Account> },
  components: { IonRow, IonCol, IonRadioGroup, IonItem, IonRadio, IonToggle, IonText, IonGrid },
  setup(props) {
    const deals = ["Everything", "Only Deals and New Menu Items (about 1x per week)"];
    const selectedDeal = ref(-1);

    //Used to temporarily disable watch when API call fails
    const isReset = ref(false);

    const commPrefs = ref(new CommunicationPreferences(props.account));
    watch(
      commPrefs,
      (newVal) => {
        if (newVal.ltdCommunicationEmail) selectedDeal.value = 1;
        if (newVal.receiveDonatosNewsAndEventEmails) selectedDeal.value = 0;
      },
      { deep: true, immediate: true }
    );

    //SELECTED DEAL RADIO GROUP
    const updateSelectedDeal = (newValue) => {
      selectedDeal.value = newValue;
      if (selectedDeal.value == 0) updatePrefs("receiveDonatosNewsAndEventEmails", true);
      if (selectedDeal.value == 1) updatePrefs("ltdCommunicationEmail", true);
    };
    watch(selectedDeal, (newVal) => {
      updateSelectedDeal(newVal);
    });

    const dealsAndProductEmails = ref(commPrefs.value.receiveDonatosNewsAndEventEmails || commPrefs.value.ltdCommunicationEmail);
    watch(dealsAndProductEmails, (newVal) => {
      //If toggling off, set both to false
      if (!newVal) {
        if (commPrefs.value.receiveDonatosNewsAndEventEmails) {
          updatePrefs("receiveDonatosNewsAndEventEmails", false);
          commPrefs.value.receiveDonatosNewsAndEventEmails = false;
          selectedDeal.value = -1;
        }
        if (commPrefs.value.ltdCommunicationEmail) {
          updatePrefs("ltdCommunicationEmail", false);
          commPrefs.value.ltdCommunicationEmail = false;
          selectedDeal.value = -1;
        }
      }
      //If toggling on, set "Everything" to true
      else {
        updatePrefs("receiveDonatosNewsAndEventEmails", true);
        commPrefs.value.receiveDonatosNewsAndEventEmails = true;
      }
    });

    const updatePrefs = (prefName: string, value: boolean) => {
      if (!isReset.value) {
        let requestObj = { customerId: props.account?.customerId };
        requestObj[prefName] = +value;
        updateCommunicationPreferences(requestObj).catch(() => {
          isReset.value = true;
          commPrefs.value[prefName] = !value;
        });
      }
      isReset.value = false;
    };

    return { deals, selectedDeal, arrowForwardOutline, commPrefs, dealsAndProductEmails, updatePrefs, updateSelectedDeal };
  },
});
