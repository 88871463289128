
import { IonInput, IonText } from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    label: String,
    placeholder: String,
    required: Boolean,
    modelValue: { required: true },
    fill: String,
    labelColor: String,
    autocomplete: String,
    type: { type: String, default: "text" },
  },
  computed: {
    rules() {
      return [this.required ? "required" : ""];
    },
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },

  components: {
    IonInput,
    IonText,
  },
});
