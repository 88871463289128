
import { defineComponent, computed } from "vue";
import { IonInput, IonText, IonNote, IonButton, IonIcon, IonSpinner } from "@ionic/vue";

import { searchSharp } from "ionicons/icons";

export default defineComponent({
  props: {
    required: Boolean,
    tabindex: { type: String, default: "" },
    label: String,
    autoComplete: Boolean,
    searchBtn: Boolean,
    searching: Boolean,
    fill: String,
    stacked: Boolean,
    error: { type: String || undefined },
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ['update:modelValue', 'enter', 'blur'],
  setup(props, { emit, attrs }) {
    const localModelValue = computed({
      get: () => props.modelValue,
      set: (newValue) => emit("update:modelValue", newValue),
    });

    return { localModelValue, searchSharp};
  },
  components: {
    IonInput,
    IonText,
    IonNote,
    IonButton,
    IonIcon,
    IonSpinner,
  },
});
