
import { defineComponent, nextTick, PropType, ref } from "vue";
import { IonButton, IonItem, IonList, IonRadioGroup, IonRadio, IonRow, IonCol, IonIcon } from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";
import addressDisplay from "@/components/addressDisplay.vue";
import addressForm from "./forms/addressForm.vue";
import { pencilOutline, trashOutline } from "ionicons/icons";
import { CustomerAddress, DeliveryAddress } from "@/models/addressModels";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    addresses: {
      type: Array as PropType<DeliveryAddress[]>,
      default: () => [],
    },
    selectable: Boolean,
    editable: Boolean,
    deleteable: Boolean,
    addBtn: Boolean,
  },
  emits: ["selected", "updateShowAddressForm"],
  setup(props, { emit }) {
    const store = useStore();
    const submissionError = ref(undefined);
    //@ts-ignore
    const defaultIndex = props.addresses.findIndex((a) => a.isFavorite);
    const selectedIndex = ref(defaultIndex);

    const deleteAddress = (address) => {
      store.dispatch("deleteUserAddress", address);
      // Address is only deletable if it's been selected, required to select a new one.
      if (props.selectable) {
        nextTick(() => {
          selectedIndex.value = 0;
          emit("selected", props.addresses[selectedIndex.value]);
        });
      }
    };

    const selectAddress = (i) => {
      selectedIndex.value = i;
      if (props.selectable) emit("selected", props.addresses[selectedIndex.value]);
    };

    if (props.addresses[selectedIndex.value] && props.selectable) {
      emit("selected", props.addresses[selectedIndex.value]);
    }

    const showList = ref(true);
    const showForm = ref(false);
    const formAddress = ref(new CustomerAddress({}));

    const toggleForm = () => {
      showList.value = !showList.value;
      showForm.value = !showForm.value;
      emit("updateShowAddressForm", showForm.value);
      submissionError.value = undefined;
    };

    const editAddress = (address) => {
      formAddress.value = address;
      toggleForm();
      saveFunction.value = updateExistingAddress;
    };

    const newAddress = () => {
      formAddress.value = new CustomerAddress({});
      saveFunction.value = saveNewAddress;
      toggleForm();
    };

    const updateExistingAddress = async (payload) => {
      store
        .dispatch("editUserAddress", payload)
        .then(() => {
          toggleForm();
        })
        .catch((err) => (submissionError.value = err));
    };

    const saveNewAddress = async (payload) => {
      store
        .dispatch("addUserAddress", payload)
        .then(() => {
          toggleForm();
          // if successfull change selection to last address
          if (props.selectable) selectedIndex.value = props.addresses.length - 1;
        })
        .catch((err) => (submissionError.value = err));
    };

    const saveFunction = ref(saveNewAddress);

    return {
      deleteAddress,
      pencilOutline,
      trashOutline,
      selectedIndex,
      selectAddress,
      showList,
      showForm,
      toggleForm,
      CustomerAddress,
      saveNewAddress,
      newAddress,
      arrowBackOutline,
      formAddress,
      editAddress,
      saveFunction,
      submissionError,
    };
  },
  components: {
    IonList,
    IonItem,
    IonButton,
    IonRow,
    IonRadioGroup,
    IonRadio,
    IonCol,
    IonIcon,
    addressDisplay,
    addressForm,
  },
});
