import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_accountModal = _resolveComponent("accountModal")!
  const _component_cartBtn = _resolveComponent("cartBtn")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, {
    role: "navigation",
    "aria-label": "desktop navigation header",
    class: "ion-no-border"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, {
        mode: "md",
        class: "desktop-navigation",
        "data-cy": "desktop-nav"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, {
            size: "12",
            class: "ion-align-items-center",
            style: {"justify-content":"space-around"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { size: "1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    to: "/",
                    "aria-label": "Home Button",
                    "data-cy": "desktop-button-logo",
                    "data-link": "nav"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_img, {
                        alt: "Donatos Logo",
                        class: "menu-logo",
                        src: require('@/assets/logo-dark.png')
                      }, null, 8, ["src"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_router_link, {
                class: "desktop-nav-link",
                "data-cy": "desktop-link-menu",
                to: { path: '/rewards', query: { utm_medium: 'web', utm_source: 'nav' } },
                replace: "",
                "data-link": "nav"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Rewards & Deals")
                ])),
                _: 1
              }),
              _createVNode(_component_router_link, {
                class: "desktop-nav-link",
                "data-cy": "desktop-link-menu",
                "data-link": "nav",
                to: {
            path: _ctx.redirectToLocation ? '/location' : '/menu',
            query: _ctx.redirectToLocation ? { redirect: '/menu', utm_medium: 'web', utm_source: 'nav' } : { utm_medium: 'web', utm_source: 'nav' },
          }
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Menu & Order")
                ])),
                _: 1
              }, 8, ["to"]),
              _createVNode(_component_router_link, {
                class: "desktop-nav-link",
                "data-cy": "desktop-link-locations",
                "data-link": "nav",
                to: { path: '/location', query: { utm_medium: 'web', utm_source: 'nav' } }
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Locations")
                ])),
                _: 1
              }),
              _createVNode(_component_router_link, {
                class: "desktop-nav-link",
                "data-cy": "desktop-link-rewards",
                "data-link": "nav",
                to: { path: '/catering', query: { utm_medium: 'web', utm_source: 'nav' } }
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Catering")
                ])),
                _: 1
              }),
              _cache[4] || (_cache[4] = _createElementVNode("a", {
                class: "desktop-nav-link",
                "data-cy": "desktop-link-franchise",
                href: "https://www.donatospizzafranchise.com/?SPPC=Offline&sppccampaignid=902738&utm_source=Scorpion%20Advertising&utm_medium=cpc&utm_campaign=Offline",
                target: "_blank"
              }, "Own a Donatos", -1)),
              _createVNode(_component_ion_col, { size: "1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, {
                    class: "ion-align-items-center",
                    style: {"justify-content":"space-between"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        size: "5",
                        class: "px-0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_accountModal, { iconBtn: "" })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, {
                        size: "5",
                        class: "px-0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_cartBtn, {
                            style: {"font-size":"30px"},
                            color: "medium",
                            "data-cy": "desktop-cart-icon"
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}