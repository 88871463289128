
import { IonInput, IonText } from "@ionic/vue";
import { defineComponent, computed } from "vue";
import { formatPhoneNumber } from "@/services/stringService";
/**
 * For area code validation with "yup" add this to schema->
 * `.test("areaCode", "Phone number area code is not valid", (value) => isAreaCodeValid(value))
 */
export default defineComponent({
  props: {
    required: Boolean,
    modelValue: { type: String, default: "" },
    fill: String,
    labelColor: String,
    dense: Boolean,
    stacked: Boolean,
    mode: String,
    error: String,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const localModelValue = computed({
      get: () => formatPhoneNumber(props.modelValue),
      set: (newValue) => {
        const phone = newValue.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/) ?? [];
        emit("update:modelValue", phone[0]);
      },
    });
    return { localModelValue };
  },

  components: { IonInput, IonText, },
});
