
import { IonInput, IonText } from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    label: String,
    placeholder: String,
    required: Boolean,
    modelValue: String,
    fill: String,
    labelColor: String,
    dense: Boolean,
    stacked: Boolean,
    mode: String,
    error: String,
    shape: String,
  },
  computed: {
    rules() {
      return [this.required ? "required" : ""];
    },
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
  methods: {
    nameType(label) {
      if (label?.toLowerCase().includes("first")) return "given-name";
      if (label?.toLowerCase().includes("last")) return "family-name";
      return "name";
    },
  },

  components: {
    IonInput,
    IonText
  },
});
