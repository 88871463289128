import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-text-capitalize" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "input-error" }
const _hoisted_8 = { class: "input-error" }
const _hoisted_9 = { class: "input-error" }
const _hoisted_10 = { class: "input-error" }
const _hoisted_11 = { class: "input-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_name = _resolveComponent("name")!
  const _component_birthday = _resolveComponent("birthday")!
  const _component_phone = _resolveComponent("phone")!
  const _component_email = _resolveComponent("email")!
  const _component_selectLocation = _resolveComponent("selectLocation")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (!_ctx.showEditView)
    ? (_openBlock(), _createBlock(_component_ion_row, {
        key: 0,
        class: "pr-8"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, {
            size: "9",
            class: "pl-16"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.account?.name), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.account.emailAddress), 1),
              (_ctx.account.phoneNumber)
                ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.account.phoneNumber?.toString()), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, {
            size: "3",
            class: "pl-16 pr-8 ion-text-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                size: "small",
                color: "primary",
                fill: "outline",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showEditView = true))
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode("Edit")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, {
            size: "12",
            class: "pl-16"
          }, {
            default: _withCtx(() => [
              (_ctx.account.hasValidBirthdate)
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                    _createTextVNode(" Birthday: " + _toDisplayString(_ctx.account.birthMonth) + " " + _toDisplayString(_ctx.account.birthDay), 1),
                    _createElementVNode("sup", null, _toDisplayString(_ctx.numberSuffix(_ctx.account.birthDay)), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("p", null, "Favorite Donatos: " + _toDisplayString(_ctx.favoriteLocationName), 1),
              (_ctx.isLoyaltyMember)
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Member ID: " + _toDisplayString(_ctx.account.rewardsMemberId), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, {
            size: "12",
            class: "pl-16"
          }, {
            default: _withCtx(() => [
              (_ctx.hasDietaryPreferences)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Dietary: " + _toDisplayString(_ctx.dietaryPreferencesList.join(", ")), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, {
            size: "12",
            class: "pl-16 interstate-bold"
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                href: _ctx.passwordResetUrl,
                target: "_blank"
              }, [
                _createVNode(_component_ion_text, {
                  fill: "clear",
                  color: "primary"
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode("Reset Password")
                  ])),
                  _: 1
                })
              ], 8, _hoisted_6)
            ]),
            _: 1
          }),
          (_ctx.account.pendingEmail)
            ? (_openBlock(), _createBlock(_component_ion_col, {
                key: 0,
                "size-xs": "12",
                class: "pl-16 interstate-bold"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_text, { color: "secondary" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Pending Email: " + _toDisplayString(_ctx.account.pendingEmail), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_text_input, {
                    class: _normalizeClass(_ctx.verificationCodeClassList),
                    fill: "outline",
                    style: {"margin-top":"10px","width":"200px"},
                    label: "Verification Code",
                    labelColor: "medium",
                    modelValue: _ctx.verificationCode,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.verificationCode) = $event))
                  }, null, 8, ["class", "modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, { size: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_name, {
                modelValue: _ctx.formValues.firstName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formValues.firstName) = $event)),
                required: "",
                label: "First name",
                labelColor: "medium",
                fill: "outline"
              }, null, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.errors.firstName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_name, {
                modelValue: _ctx.formValues.lastName,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formValues.lastName) = $event)),
                required: "",
                label: "Last name",
                labelColor: "medium",
                fill: "outline"
              }, null, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.errors.lastName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_birthday, {
            birthdateString: _ctx.account.birthdate,
            onUpdateBirthdate: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateBirthdate($event)))
          }, null, 8, ["birthdateString"]),
          _createVNode(_component_ion_col, { size: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_phone, {
                modelValue: _ctx.formValues.phoneNumber,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formValues.phoneNumber) = $event)),
                label: "Phone Number",
                labelColor: "medium",
                fill: "outline"
              }, null, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.errors.phoneNumber), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_email, {
                modelValue: _ctx.formValues.emailAddress,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formValues.emailAddress) = $event)),
                required: "",
                label: "Email",
                labelColor: "medium",
                fill: "outline"
              }, null, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.errors.emailAddress), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_text_input, {
                modelValue: _ctx.formValues.zipCode,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formValues.zipCode) = $event)),
                required: "",
                label: "Zip Code",
                labelColor: "medium",
                fill: "outline",
                autocomplete: "postal-code",
                type: "text"
              }, null, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.errors.zipCode), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_selectLocation, {
                label: _ctx.formValues.zipCode?.length === 5 ? 'Favorite Donatos' : 'Favorite Donatos - Enter Zip Code',
                locations: _ctx.locationList,
                disabled: _ctx.formValues.zipCode?.length !== 5,
                dataCy: "rewards-location-select",
                fill: "outline",
                modelValue: _ctx.formValues.favoriteStore,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formValues.favoriteStore) = $event))
              }, null, 8, ["label", "locations", "disabled", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_text, null, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode("Dietary (check all that apply)")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_checkbox, {
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.glutenFree = $event)),
                    modelValue: _ctx.glutenFree,
                    color: "success",
                    "label-placement": "end",
                    class: "sel-option",
                    justify: "start"
                  }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createTextVNode("Gluten Free")
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_checkbox, {
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.vegan = $event)),
                    modelValue: _ctx.vegan,
                    color: "success",
                    "label-placement": "end",
                    class: "sel-option",
                    justify: "start"
                  }, {
                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                      _createTextVNode("Vegan")
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_checkbox, {
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.vegetarian = $event)),
                    modelValue: _ctx.vegetarian,
                    color: "success",
                    "label-placement": "end",
                    class: "sel-option",
                    justify: "start"
                  }, {
                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createTextVNode("Vegetarian")
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                id: "save-button",
                onClick: _ctx.onSubmit
              }, {
                default: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createTextVNode("Save")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}