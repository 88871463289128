
import { computed, defineComponent, ref, watch } from "vue";
import { IonSelect, IonSelectOption, IonCol, IonText } from "@ionic/vue";
import { CAL_DAYS, MONTHS } from "@/constants";
import { capitalize } from "@/services/stringService";
import { DateTime } from "luxon";

export default defineComponent({
  props: { birthdateString: { type: String, required: true } },
  emits: ["updateBirthdate"],
  setup(props, { emit }) {
    const months = MONTHS;
    const birthdate = DateTime.fromISO(props.birthdateString);
    const isValidBirthdate = birthdate.isValid;

    // month index ex. (0 is 'jan' && 11 is 'dec')
    const month = ref(0);
    const day = ref(1);
    const year = ref(1964);

    // luxon returns actual month number rather than index,
    // subtracting 1 to match index.
    month.value = birthdate.month - 1;
    day.value = birthdate.day;

    const numberOfDays = computed(() => CAL_DAYS[months[month.value]]);

    watch(month, (m) => {
      if (m !== null && !day.value) day.value = 1;
      else if (m === 1 && day.value >= 29) day.value = 29;
      else if (day.value > numberOfDays.value.length) day.value = 30;
      else emit("updateBirthdate", getBirthDate());
    });

    watch(day, () => {
      emit("updateBirthdate", getBirthDate());
    });

    const getBirthDate = () => {
      // return new Date(`${month.value + 1}/${day.value}/${year.value}`).toISOString().slice(0, 10);

      const date = new Date(year.value, month.value, day.value);
      return date.toLocaleString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
    };

    return { months, day, month, numberOfDays, capitalize, isValidBirthdate };
  },
  components: { IonSelect, IonSelectOption, IonCol, IonText },
});

// import
// import Birthday from "@/components/inputs/birthday.vue";

// component
// <birthday :birthdateString="account.birthdate" @updateBirthdate="updateBirthdate($event)" />

// listener
//  const updateBirthdate = (date) => { console.log("updating Birthdate: ", date); };
